import {
  maxLength,
  required,
  email,
  minLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";
import { phoneCheck } from "~/utils/phoneHelper";

export const requiredValidate = () =>
  helpers.withMessage(_T("@Entry field required"), required);

export const sameAsValidate = (errorMessage, sameWithValue) =>
  helpers.withMessage(_T(errorMessage), sameAs(sameWithValue));

export const minLengthValidate = (errorMessage, length) =>
  helpers.withMessage(`${_T(errorMessage)} ${length}`, minLength(length));

export const maxLengthValidate = (errorMessage, length) =>
  helpers.withMessage(`${_T(errorMessage)} ${length}`, maxLength(length));

export const regExpValidate = (errorMessage, regExp) =>
  helpers.withMessage(_T(errorMessage), helpers.regex(regExp));

export const emailValidate = () =>
  helpers.withMessage(_T("@You entered an incorrect email"), email);

export const phoneValidate = () =>
  helpers.withMessage(
    _T("@You entered an incorrect phone number"),
    (value) => !helpers.req(value) || phoneCheck(value),
  );
