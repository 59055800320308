<template>
  <div class="input__w">
    <div class="input">
      <input
        v-model.trim="getValue"
        class="input__field"
        type="text"
        :disabled="disabled"
        :autocomplete="autocompleteType"
        :maxlength="maxlength"
        :minlength="minlength"
        :class="[
          { filled: getValue },
          { error: (validate.$error && validate.$dirty) || customError },
        ]"
        @blur="onBlur"
      />
      <span class="input__caption" :class="{ required }">
        {{ _T(caption) }}
      </span>
      <div
        v-show="(validate.$error && validate.$dirty) || customError"
        class="input__error-img"
      >
        <img src="~~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div
      v-show="(validate.$error && validate.$dirty) || customError"
      class="input__error-caption-w"
    >
      <p v-if="null !== customError">
        {{ customError }}
      </p>
      <p v-else-if="validate.$error && validate.$dirty">
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  caption: { type: String, required: true },
  validate: { type: Object, required: true },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  modelValue: { type: String, required: false },
  customError: { required: false, default: null },
  autocompleteType: { type: String, required: false },
  required: { type: Boolean, required: false, default: true },
  disabled: { type: Boolean, required: false, default: false },
});

const emits = defineEmits(["update:modelValue"]);

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => emits("update:modelValue", value),
});

function onBlur() {
  props.validate.$touch();
}
</script>

<style lang="scss" scoped></style>
